import { domainAuthenticationMap, proxyUrl } from "../config";
import { getJWT } from "../gProxy";
import { extractHeader } from "../utils/extractHeader";
import { resolveDomain } from "../utils/resolveDomain";

const interceptedRequests: string[] = [];

/**
 * Before a request is sent, we'll need to determine if it should be
 * directed through the proxy.
 */
export async function before(request: XHook.IRequest): Promise<void> {
  const securityMethodHeader = extractHeader("X-Security-Method", request);
  const proxyHeader = extractHeader("X-Proxy", request);

  // Abort if the url is relative (the gateway exclusively supports
  // absolute urls)
  if (!request.url || request.url.indexOf("//") === -1) {
    return;
  }

  const domain = resolveDomain(request.url);
  const authMethod =
    securityMethodHeader || domainAuthenticationMap.get(domain);

  // Handle special cases, where we should bail out of authentication
  switch (authMethod) {
    case "pm2":
      // The SiteCore endpoint is used internally by the legacy pm2
      // frontend, we need these requests to be performed directly
      // without use of Gateway v3!
      if (request.url.indexOf("/sitecore/") !== -1) {
        return;
      }
      break;

    default:
    // Allow all other auth methods regardless of input
  }

  // If the request requires any form of authentication, then make sure to
  // apply these configurations
  if (authMethod || String(proxyHeader).toLowerCase() === "true") {
    // cache list of intercepted requests
    interceptedRequests.splice(20);
    interceptedRequests.unshift(request.url);

    // Configure authentication
    request.headers["X-Security-Method"] = authMethod || "IP";
    request.headers["X-Access-Token"] = await getJWT();

    // Direct the request to the proxy
    let proxyRoute: string;

    // Some methods need custom routing...
    switch (authMethod) {
      case "acapela-vaas.com":
        proxyRoute =
          "proxy/acapelavaas/" +
          encodeURIComponent(request.url) +
          "?noCache=" +
          new Date().getTime();
        break;

      default:
        proxyRoute =
          "proxy/?url=" +
          encodeURIComponent(request.url) +
          "&noCache=" +
          new Date().getTime();
    }

    request.url = proxyUrl + proxyRoute;
  }
}

/**
 * expose the list of the last 20 intercepted request urls, to confirm
 * whether or not a request has been run through the gProxy script.
 */
export function getInterceptedRequests(): string[] {
  return interceptedRequests.map((it) => it);
}

export const AuthMiddleware = {
  before,
  getInterceptedRequests,
};
