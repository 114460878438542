import xhook from "xhook";

import { AuthMiddleware } from "./middleware/auth";
import { ScrapingMiddleware } from "./middleware/scraper";

interface IMiddleware {
  before?: (request: XHook.IRequest) => Promise<XHook.IResponse | void>;
  after?: (request: XHook.IRequest, response: XHook.IResponse) => Promise<void>;
}

/**
 * Contains a list of middlewares to run requests through.
 * @private
 */
const middlewares: IMiddleware[] = [AuthMiddleware, ScrapingMiddleware];

/**
 * Run through all middlewares and trigger the "before" handler, and
 * accumulate changes to the XHR-object.
 */
async function before(
  request: XHook.IRequest,
  callback: (response?: XHook.IResponse) => void
) {
  for (const middleware of middlewares) {
    // Skip middlewares with no "before" handler
    if (typeof middleware.before !== "function") {
      continue;
    }

    // Trigger the handler
    const response = await middleware.before(request);

    // If the middleware supplied a response, then resolve the request
    // immediately
    if (response) {
      callback(response);
      return;
    }
  }

  // If we get here, dispatch the request
  callback();
}

/**
 * Run through all middlewares and trigger the "after" handler, and
 * accumulate changes to the XHR-object.
 */
async function after(
  request: XHook.IRequest,
  response: XHook.IResponse,
  callback: (response: XHook.IResponse) => void
) {
  for (const middleware of middlewares) {
    // Skip middlewares with no "after" handler
    if (typeof middleware.after !== "function") {
      continue;
    }

    // Trigger the handler
    await middleware.after(request, response);
  }

  callback(response);
}

// Setup XHook
xhook.before((request, callback) => {
  if (!!callback) {
    before(request, callback);
  }
});
xhook.after((request, response, callback) => {
  if (!!callback) {
    after(request, response, callback);
  }
});

// We need to ensure that onabort is set to null by default, in order to
// prevent jQuery from failing
XMLHttpRequest.prototype.onabort = null;
