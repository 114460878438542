/**
 * Helper that extracts the header with the given name from the request (
 * regardless of casing).
 */
export function extractHeader(
  header: string,
  request: XHook.IRequest
): string | null {
  const lowercasedHeader = header.toLowerCase();
  const headerNames = Object.keys(request.headers);

  // Return the matched header
  for (const headerName of headerNames) {
    if (!Object.prototype.hasOwnProperty.call(request.headers, headerName)) {
      continue;
    }

    if (headerName.toLowerCase() === lowercasedHeader) {
      // eslint-disable-next-line security/detect-object-injection
      return request.headers[headerName];
    }
  }

  // If we get here, nothing was found - bail out
  return null;
}
