import { extractHeader } from "../utils/extractHeader";

/**
 * Before a request is sent, we'll need to determine if it should be
 * directed through the parser.
 */
async function before(request: XHook.IRequest): Promise<void> {
  const scrapingMethodHeader = extractHeader("X-Custom-Method", request);

  switch (scrapingMethodHeader) {
    case "PortalPanel":
      request.url = request.url.replace("/proxy/", "/scraper/portalpanel/");
      break;
  }
}

export const ScrapingMiddleware = {
  before,
};
