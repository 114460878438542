import { domainForwardMap } from "./config";
import { forwardTo } from "./gProxy";
import { resolveDomain } from "./utils/resolveDomain";

/**
 * Contains a reference to the original `window.open` method.
 * @private
 */
const originalMethod = window.open;

/**
 * Takes over the `window.open` method and makes sure that requests to
 * domains that require authentication are forwarded through the proxy
 * backend.
 */
export const open: typeof window.open = (url, ...args) => {
  if (!url) {
    return originalMethod.call(window, url, ...args);
  }

  const domain = resolveDomain(url);

  // Determine if we should forward the user to this address?
  if (domainForwardMap.has(domain)) {
    url = forwardTo(url);
  }

  // Actually open the window
  return originalMethod.call(window, url, ...args);
};
