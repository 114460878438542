/** Path to the Node.js proxy */
export const proxyUrl = process.env.GATEWAY_URL ?? "/";

/**
 * Hardcoded map of domain -> authentication methods, we need this to
 * determine which requests to intercept.
 */
export const domainAuthenticationMap = new Map<string, string>([
  ["userdata.gyldendal.dk", "userdata.gyldendal.dk"],
  ["api.test.userdata.gyldendal.dk", "userdata.gyldendal.dk"],
  ["userdata-v2.gyldendal.dk", "userdata-v2.gyldendal.dk"],
  ["qa-userdata.gyldendal.dk", "userdata-v2.gyldendal.dk"],
  ["test-userdata-v2.gyldendal.dk", "userdata-v2.gyldendal.dk"],
  ["ibog-api.nimta.com", "GetLibrary"],
  ["notebook.gyldendal.dk", "Notebook"],
  ["test.notebook.gyldendal.dk", "Notebook"],
  ["test-notebook.gyldendal.dk", "Notebook"],
  ["api.flickr.com", "Flickr"],
  ["www.skoletube.dk", "Skoletube"],
  ["skoletube.dk", "Skoletube"],
  ["ibog30.gyldendal.dk", "ibog30.gyldendal.dk"],
  ["test-ibog30.gyldendal.dk", "ibog30.gyldendal.dk"],
  ["udv.ibog30.gyldendal.dk", "ibog30.gyldendal.dk"],
  ["cms.laesloes.gyldendal.dk", "ibog30.gyldendal.dk"],
  ["udv.laesloes.gyldendal.dk", "ibog30.gyldendal.dk"],
  ["componentframework.gyldendal.dk", "components.gyldendal.dk"],
  ["qa-componentframework.gyldendal.dk", "components.gyldendal.dk"],
  ["test-componentframework.gyldendal.dk", "components.gyldendal.dk"],
  ["bridge.components.gyldendal.dk", "components.gyldendal.dk"],
  ["bridge.test.components.gyldendal.dk", "components.gyldendal.dk"],
  ["vaas.acapela-group.com", "acapela-vaas.com"],
  ["test-api-webproever.gyldendal.dk", "webprover"],
  ["test-api-preview-webproever.gyldendal.dk", "webprover"],
  ["qa-api-webproever.gyldendal.dk", "webprover"],
  ["qa-api-preview-webproever.gyldendal.dk", "webprover"],
  ["api-webproever.gyldendal.dk", "webprover"],
  ["api-preview-webproever.gyldendal.dk", "webprover"],
  [
    "gyldendalaccessservice.gyldendal.dk",
    "gyldendalaccessservice.gyldendal.dk",
  ],
  [
    "qa-gyldendalaccessservice.gyldendal.dk",
    "gyldendalaccessservice.gyldendal.dk",
  ],
  [
    "test-gyldendalaccessservice.gyldendal.dk",
    "test-gyldendalaccessservice.gyldendal.dk",
  ],
  ["dev-apim.gyldendal.dk", "apim"],
  ["gdapim-production.azure-api.net", "apim"],
  ["loginconnector.gyldendal.dk", "loginconnector.gyldendal.dk"],
  ["qa-loginconnector.gyldendal.dk", "loginconnector.gyldendal.dk"],
  ["test-loginconnector.gyldendal.dk", "loginconnector.gyldendal.dk"],
  ["bigdataapi.miraclecloudcontrol.com", "IP"],
  ["test-bigdata-api.gyldendal.dk", "IP"],
  ["test.bigdata-api.gyldendal.dk", "IP"],
  ["bigdata-api.gyldendal.dk", "IP"],
  ["test.api.ibog4.gyldendal.dk", "ibog40.gyldendal.dk"],
  ["test-api-ibog4.gyldendal.dk", "ibog40.gyldendal.dk"],
  ["qa.api.ibog4.gyldendal.dk", "ibog40.gyldendal.dk"],
  ["qa-api-ibog4.gyldendal.dk", "ibog40.gyldendal.dk"],
  ["api.ibog4.gyldendal.dk", "ibog40.gyldendal.dk"],
  ["api-ibog4.gyldendal.dk", "ibog40.gyldendal.dk"],
  ["support.gyldendal.dk", "IP"],
  ["test.support.gyldendal.dk", "IP"],
  ["test-support.gyldendal.dk", "IP"],
  ["qa-support.gyldendal.dk", "IP"],
  ["kommunestatistik.gyldendal.dk", "kommunestatistik.gyldendal.dk"],
  ["test-kommunestatistik.gyldendal.dk", "kommunestatistik.gyldendal.dk"],
  ["test-opgavekompleks.gyldendal.dk", "opgavekompleks.gyldendal.dk"],
  ["test.opgavekompleks.gyldendal.dk", "opgavekompleks.gyldendal.dk"],
  ["qa.opgavekompleks.gyldendal.dk", "opgavekompleks.gyldendal.dk"],
  ["cms.opgavekompleks.gyldendal.dk", "opgavekompleks.gyldendal.dk"],
  ["opgavekompleks.gyldendal.dk", "opgavekompleks.gyldendal.dk"],
  ["medialibrary.gyldendal.dk", "api.medialibrary.gyldendal.dk"],
  ["test-medialibrary.gyldendal.dk", "api.medialibrary.gyldendal.dk"],
  ["test.api.medialibrary.gyldendal.dk", "api.medialibrary.gyldendal.dk"],
  ["api.medialibrary.gyldendal.dk", "api.medialibrary.gyldendal.dk"],
  ["test.portalmotor.gyldendal.dk", "pm2"],
  ["test-portalmotor.gyldendal.dk", "pm2"],
  ["test-api-portalmotor.gyldendal.dk", "pm2"],
  ["test-api-preview-portalmotor.gyldendal.dk", "pm2"],
  ["qa.portalmotor.gyldendal.dk", "pm2"],
  ["qa-portalmotor.gyldendal.dk", "pm2"],
  ["qa-api-portalmotor.gyldendal.dk", "pm2"],
  ["qa-api-preview-portalmotor.gyldendal.dk", "pm2"],
  ["cms.portalmotor.gyldendal.dk", "pm2"],
  ["portalmotor.gyldendal.dk", "pm2"],
  ["api-portalmotor.gyldendal.dk", "pm2"],
  ["api-preview-portalmotor.gyldendal.dk", "pm2"],
  ["func-clickstream-test-001.azurewebsites.net", "clickstream"],
  ["func-clickstream-prod-001.azurewebsites.net", "clickstream"],
  ["func-clickstream-test-001-v2.azurewebsites.net", "clickstream"],
  ["func-clickstream-prod-001-v2.azurewebsites.net", "clickstream"],
]);

/**
 * Hardcoded map of domains that need to be forwarded through the node.js
 * proxy before being opened.
 */
export const domainForwardMap = new Map<string, string>([
  ["bridge.components.gyldendal.dk", "componentBridge"],
  ["bridge.test.components.gyldendal.dk", "componentBridge"],
  ["componentframework.gyldendal.dk", "componentBridge"],
  ["test-componentframework.gyldendal.dk", "componentBridge"],
  ["opgavegenerator.gyldendal.dk", "opgavegenerator.gyldendal.dk-forward"],
  ["udv.opgavegenerator.gyldendal.dk", "opgavegenerator.gyldendal.dk-forward"],
  ["cas.gyldendal.dk", "cas"],
]);
