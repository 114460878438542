import { getJWT } from "./gProxy";

type CompleteResponse = {
  code: number;
  status: "ok" | "error" | string;
  data: {
    code: number;
    status: "ok" | "error" | string;
    data: string;
  };
};

type OnCompleteCallback = (
  responseTextOrStatusCode: string | number,
  status: "ok" | "error" | string,
  completeResponse: CompleteResponse
) => void;

type OnSuccessCalllback = (
  responseText: string,
  completeResponse: CompleteResponse
) => void;

type OnErrorCallback = (
  statusCode: number,
  responseText: string,
  completeResponse: CompleteResponse
) => void;

// Proxy-specific functions
export const Proxy = {
  /** This function sends a GET-request to the gateway */
  get({
    url,
    data,
    headers,
    onComplete,
    onSuccess,
    onError,
  }: {
    url: string;
    data?: Record<string, string>;
    headers?: { [key: string]: string };
    onComplete?: OnCompleteCallback;
    onSuccess?: OnSuccessCalllback;
    onError?: OnErrorCallback;
  }): void {
    // Add get parameters?
    if (data) {
      // Split hash URL
      const splitUrl = url.split("#");

      // Add GET-parameters
      splitUrl[0] +=
        (splitUrl[0].indexOf("?") === -1 ? "?" : "&") + encodeHTTPQuery(data);

      // Combine with has URL
      url = splitUrl.join("#");
    }

    // Prepare headers
    headers = headers || {};

    // Send the request!
    request({
      // Post to the GET-endpoint
      method: "GET",
      url,

      // Send along headers
      headers,

      // Store callback-handlers
      onComplete,
      onSuccess,
      onError,
    });
  },

  /** This function sends a POST-request to the gateway */
  post({
    url,
    method,
    data,
    headers,
    onComplete,
    onSuccess,
    onError,
  }: {
    url: string;
    method?: "POST" | "DELETE" | "PUT";
    data?: string | Record<string, string>;
    headers?: Record<string, string>;
    onComplete?: OnCompleteCallback;
    onSuccess?: OnSuccessCalllback;
    onError?: OnErrorCallback;
  }): void {
    // Send the request!
    request({
      // Send the specified token as a normal POST-request
      data: encodeHTTPQuery(data),

      // Send along headers
      headers,

      // Specify method
      method: method || "POST",

      // Store callback-handlers
      onComplete,
      onSuccess,
      onError,

      // Reach out to the requested endpoint
      url,
    });
  },
};

/**
 * Encodes an associative array to a HTTP-query string.
 *
 * @param {object} data The associative array / object to encode.
 * @param {string} prefix A prefix to add to any keys in the data.
 *
 * @return {string} The encoded HTTP-query.
 *
 * @version v1.0, 09-09-2013
 * @access private
 *
 * @author Mads Felskov Agersten <mads@felskov-aps.dk>
 */
function encodeHTTPQuery(
  data: Record<string, string> | string | undefined,
  prefix?: string
): string {
  if (!data) {
    return "";
  }

  // Send strings directly
  if (typeof data === "string") {
    return data;
  }

  // Default prefix is an empty string
  prefix = prefix || "";

  // Prepare the response-query
  let query = "";

  // Loop through the object and format data
  for (const key in data) {
    if (!Object.prototype.hasOwnProperty.call(data, key)) {
      continue;
    }

    // Extend key with prefix
    const encodedKey = prefix + (prefix ? "[" : "") + key + (prefix ? "]" : "");

    // Encode regular strings
    // eslint-disable-next-line security/detect-object-injection
    if (typeof (data[key] || "") !== "object") {
      query +=
        // eslint-disable-next-line security/detect-object-injection
        (query ? "&" : "") + encodedKey + "=" + encodeURIComponent(data[key]);

      // ... Or recursively encode objects
    } else {
      query +=
        // eslint-disable-next-line security/detect-object-injection
        (query ? "&" : "") + encodeHTTPQuery(data[key], encodedKey);
    }
  }

  // Return the encoded query
  return query;
}

/** Helper that executes an AJAX-request with the specified configs */
async function request({
  method,
  url,
  data,
  headers,
  onComplete,
  onSuccess,
  onError,
}: {
  method: "GET" | "POST" | "PUT" | "DELETE";
  url: string;
  data?: string;
  headers?: Record<string, string>;
  onComplete?: OnCompleteCallback;
  onSuccess?: OnSuccessCalllback;
  onError?: OnErrorCallback;
}) {
  // Create a new XMLHttpRequest-client
  const ajax = new XMLHttpRequest();

  // Subscribe to the readyStateChange event
  ajax.onreadystatechange = async () => {
    // If the request has not completed yet, then abort
    if (ajax.readyState !== 4) {
      return;
    }

    const completeResponse = {
      access_token: await getJWT(),
      code: 0,
      data: {
        code: 0,
        status: "",
        data: "",
      },
      id: "",
      requestID: "",
      status: "",
    };

    // Handle "OK" requests
    if (ajax.status === 200) {
      completeResponse.status = "ok";
      completeResponse.code = 200;
      completeResponse.data = {
        code: 200,
        data: ajax.responseText,
        status: "ok",
      };

      if (onSuccess) {
        onSuccess(ajax.responseText, completeResponse);
      }
      if (onComplete) {
        onComplete(ajax.responseText, "ok", completeResponse);
      }

      // Handle error response
    } else {
      completeResponse.status = "error";
      completeResponse.code = ajax.status;
      completeResponse.data = {
        code: ajax.status,
        data: ajax.responseText,
        status: "error",
      };

      if (onError) {
        onError(ajax.status, ajax.responseText, completeResponse);
      }
      if (onComplete) {
        onComplete(ajax.status, "error", completeResponse);
      }
    }
  };

  // Submit the request
  // eslint-disable-next-line security/detect-non-literal-fs-filename
  ajax.open(method, url, true);

  let xSecurityMethodApplied = false;

  for (const key in headers) {
    if (!Object.prototype.hasOwnProperty.call(headers, key)) {
      continue;
    }

    if (key.toLowerCase() === "x-security-method") {
      xSecurityMethodApplied = true;
    }

    // eslint-disable-next-line security/detect-object-injection
    ajax.setRequestHeader(key, headers[key]);
  }

  if (!xSecurityMethodApplied) {
    ajax.setRequestHeader("X-Proxy", "true");
  }

  ajax.send(data || null);
}

// Expose GSDK publicly
window.GSDK = {
  Proxy,
};

declare global {
  interface Window {
    GSDK: {
      Proxy: typeof Proxy;
    };
  }
}
