// eslint-disable-next-line import/order
import { fetch, originalFetch } from "./fetch";

import "./xhr";
import "./open";
import xhook from "xhook";

import { domainForwardMap, proxyUrl } from "./config";
import { AuthMiddleware } from "./middleware/auth";
import { resolveDomain } from "./utils/resolveDomain";

import "./gsdk";

export interface IToken {
  AppId?: string;
  UserId?: string;
  Language?: string;

  [key: string]: string | undefined;
}

/**
 * Specification of the response from the LoginConnector
 * /api/GetAuthenticationToken endpoint.
 */
interface ILoginConnectorTokenResponse {
  AuthenticationToken: string;
}

/**
 * Specification of the response from the Gateway v3 /auth/loginConnector
 * endpoint.
 */
interface IGatewayLoginConnectorAuthResponse {
  access_token: string;
  user_info: {
    authenticationInfos: {
      belongsTo: string;
      loginProvider: "UNIC" | "EKEY" | "IP" | "WAYF";
      userIdentifier: string;
    }[];
  };
}

/**
 * Trigger this method to resolve the authentication-promise, which lets the
 * plugin know that the JWT has been fetched.
 *
 * @private
 */
let resolveAuthPromise: () => void;

/**
 * Contains a promise that's resolved once the JWT has been loaded.
 * @private
 */
const authPromise = new Promise<void>((resolve) => {
  resolveAuthPromise = resolve;
});

/**
 * Contains runtime state for the proxy client.
 * @private
 */
const state: {
  clientId: string;
  config: Map<string, string | undefined>;
  jwt: string;
  script: string;
  token: IToken | null;
} = {
  clientId: String(Math.round(Math.random() * 10000)),
  config: new Map(),
  jwt: "",
  script: "",
  token: null,
};

/**
 * Initializes the plugin by auto-detecting configurations, parsing tokens
 * and authorizing the user.
 */
function init() {
  // We need to find a reference to the script-element that embedded this
  // plugin, in order to extract configurations from there
  for (
    let scripts = document.getElementsByTagName("script"),
      i = -1,
      j = scripts.length;
    ++i < j;

  ) {
    const script = scripts[Number(i)];
    const scriptUrl = String(script.getAttribute("src"));

    // Determine if this is a reference to our script (if not, continue to
    // next)
    if (
      (scriptUrl.indexOf(".gyldendal.dk") === -1 &&
        scriptUrl.indexOf("localhost") === -1) ||
      scriptUrl.indexOf("gProxy") === -1
    ) {
      continue;
    }

    // Parse out all configurations from the URL (?-queries are supported)
    const query = (scriptUrl.split("#")[0].split("?")[1] || "").split("&");

    for (let x = -1, y = query.length; ++x < y; ) {
      const queryPart = query[Number(x)];
      const key = queryPart.substr(0, queryPart.indexOf("="));
      const value = queryPart.substr(key.length + 1);

      state.config.set(key, value);
    }

    // Support for data-token config
    if (script.getAttribute("data-token")) {
      state.config.set("token", script.getAttribute("data-token") ?? undefined);
    }

    // Cache script URL
    state.script = scriptUrl;
  }

  // The proxy supports client-side authentication - we need to detect if
  // tokens have been supplied in the URL.
  const hash = {
    jwt: window.location.hash.match(/(\?|\&)proxy\_jwt\=([^\&]+)/i),
    token: window.location.hash.match(/(\?|\&)proxy\_token\=([^\&]+)/i),
  };

  if (hash.jwt && hash.token) {
    // Cache values internally
    state.jwt = hash.jwt[2];
    state.config.set("token", hash.token[2]);

    resolveAuthPromise();

    // Remove values from the URL immediately
    window.location.hash = window.location.hash
      .replace(/(\?|\&)proxy\_jwt\=([^\&]+)/gi, "")
      .replace(/(\?|\&)proxy\_token\=([^\&]+)/gi, "");
  }

  // If an access-token is given, then register it
  else if (
    window.location.href.indexOf("access_token=") !== -1 &&
    window.location.href.indexOf("authorized_by=amazon.proxy.gyldendal.dk") !==
      -1
  ) {
    // Register the token value
    state.jwt = window.location.href
      .split("access_token=")[1]
      .split("&")[0]
      .split("#")[0];

    resolveAuthPromise();
  }

  // fall back to attempting to authenticate gdt token
  else if (state.config.has("token")) {
    authenticateToken();
  }
}

/**
 * Triggers a request to the proxy backend to authenticate the user based on
 * the given token.
 *
 * @private
 */
export function authenticateToken(token?: string): Promise<string> {
  return new Promise((resolve, reject) => {
    // Create a new XMLHttpRequest-client
    const ajax = new XMLHttpRequest();

    // Subscribe to the readyStateChange event
    ajax.onreadystatechange = () => {
      // If the request has not completed yet, then abort
      if (ajax.readyState !== 4) {
        return;
      }

      // Parse the response
      try {
        const response = JSON.parse(ajax.responseText) as {
          access_token: string;
        };

        state.jwt = response.access_token;

        resolveAuthPromise();
        resolve(ajax.responseText);
      } catch (err) {
        reject(ajax.responseText);
      }
    };

    // Update cached token
    if (token) {
      updateToken(parseToken(token));
    }

    // Open the request URL
    ajax.open("POST", proxyUrl + "auth/gdt", true);
    ajax.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    ajax.send(
      "gdt=" + encodeURIComponent(token ?? state.config.get("token") ?? "")
    );
  });
}

/**
 * Helper that fetches a guest JWT token, which the frontend will be able to
 * use when interacting with the Gateway.
 */
export function fetchGuestToken(): Promise<void> {
  return new Promise((resolve, reject) => {
    // Create a new XMLHttpRequest-client
    const ajax = new XMLHttpRequest();

    // Subscribe to the readyStateChange event
    ajax.onreadystatechange = () => {
      // If the request has not completed yet, then abort
      if (ajax.readyState !== 4) {
        return;
      }

      // Parse the response
      try {
        const response = JSON.parse(ajax.responseText) as {
          access_token?: string;
        };

        if (!response.access_token) {
          throw new Error("Access token is missing");
        }

        state.jwt = response.access_token;
        state.token = {
          UserId: "guest",
        };

        resolveAuthPromise();
        resolve();
      } catch (err) {
        reject(ajax.responseText);
      }
    };

    ajax.open("GET", proxyUrl + "auth/guest", true);
    ajax.send();
  });
}

/**
 * Helper that checks to see if the user is currently signed in to the, and
 * then fetches a JWT auth token for him to interact with Gyldendal Gateway
 * v3.
 *
 * If the user is not signed in, a guest token is fetched.
 */
export function fetchLoginConnectorToken(
  env: "dev" | "development" | "test" | "staging" | "production" | string,
  profile: string
): Promise<{
  /** Contains the user's authentication token from the LoginConnector */
  loginConnectorToken?: string;

  /** Contains data about all the profiles the user is signed into */
  userInfo: {
    /** The id of the signed in user */
    userId: string;

    /** The id of the institution that the user is associated with */
    institutionId?: string;

    /** Specifies the login provider for this user profile */
    provider: "UNIC" | "EKEY" | "WAYF" | "IP";
  }[];
}> {
  return new Promise((resolve, reject) => {
    // Temporarily disable gProxy, so we can interact directly with the
    // LoginConnector endpoints!
    disable();

    // Create a new XMLHttpRequest-client
    const ajax = new XMLHttpRequest();

    // Subscribe to the readyStateChange event
    ajax.onreadystatechange = async () => {
      let response: ILoginConnectorTokenResponse | { error: true };

      // If the request has not completed yet, then abort
      if (ajax.readyState !== 4) {
        return;
      }

      // Parse the response
      try {
        response = JSON.parse(ajax.responseText);
      } catch (err) {
        response = { error: true };
      }

      // Force response to be of type object
      if (!response || typeof response !== "object") {
        response = { error: true };
      }

      // If a user has been signed in, then we need to generate a JWT
      // token for him...
      if (!("error" in response) && response.AuthenticationToken) {
        const loginConnectorToken = response.AuthenticationToken;

        // Create a new XMLHttpRequest-client
        const ajax2 = new XMLHttpRequest();

        // Subscribe to the readyStateChange event
        ajax2.onreadystatechange = () => {
          let response2: IGatewayLoginConnectorAuthResponse | { error: true };

          // If the request has not completed yet, then abort
          if (ajax2.readyState !== 4) {
            return;
          }

          // Parse the response
          try {
            response2 = JSON.parse(ajax2.responseText);
          } catch (err) {
            response2 = { error: true };
          }

          // Force response to be of type object
          if (!response2 || typeof response2 !== "object") {
            response2 = { error: true };
          }

          // If no errors occurred, then cache the received JWT
          if (!("error" in response2) && response2.access_token) {
            // Cache internally
            state.jwt = response2.access_token;
            resolveAuthPromise();

            // Update the registerred token
            state.token = {
              AppId: profile,
              InstId: response2.user_info.authenticationInfos[0].belongsTo,
              LoginProvider:
                response2.user_info.authenticationInfos[0].loginProvider,
              UserId: response2.user_info.authenticationInfos[0].userIdentifier,
            };

            // Resolve returned promise...
            resolve({
              /** Contains the user's authentication token from the LoginConnector */
              loginConnectorToken,

              /** Contains data about all the profiles the user is signed into */
              userInfo: response2.user_info.authenticationInfos.map(
                (authInfo) => ({
                  institutionId: authInfo.belongsTo,
                  provider: authInfo.loginProvider,
                  userId: authInfo.userIdentifier,
                })
              ),
            });
          } else {
            // Reject returned promise...
            reject(ajax2.responseText);
          }
        };

        ajax2.open("POST", proxyUrl + "auth/loginconnector", true);

        ajax2.setRequestHeader("Content-Type", "application/json");

        ajax2.send(
          JSON.stringify({
            appId: profile,
            loginConnectorToken,
          })
        );

        // If we couldn't fint a signed in user, then fetch a guest
        // token from the user...
      } else {
        try {
          await fetchGuestToken();

          // Resolve returned promise once the guest token has
          // been fetched...
          resolve({ userInfo: [] });
        } catch (err) {
          reject(err);
        }
      }
    };

    // Fetch token from LoginConnector depending on environment...
    let lcUrl: string;

    switch (env) {
      case "dev":
      case "development":
      case "test":
        lcUrl = `https://test-loginconnector.gyldendal.dk/api/GetAuthenticationToken/Get?clientName=`;
        break;

      case "staging":
        lcUrl = `https://qa-loginconnector.gyldendal.dk/api/GetAuthenticationToken/Get?clientName=`;
        break;

      default:
        lcUrl = `https://loginconnector.gyldendal.dk/api/GetAuthenticationToken/Get?clientName=`;
    }

    // Forward credentials when fetching the token
    ajax.withCredentials = true;

    // Submit the request
    ajax.open("GET", `${lcUrl}${encodeURIComponent(profile)}`, true);
    ajax.send();

    // Re-enable the gProxy right away, after we've dispatched the
    // request to fetch the user's token directly from the
    // LoginConnector
    enable();
  });
}

/**
 * Helper that verifies a token from the LoginConnector and applies it as an
 * internal Gateway token.
 */
export function applyLoginConnectorToken(
  profile: string,
  loginConnectorToken: string
): Promise<{
  /** Contains the user's authentication token from the LoginConnector */
  loginConnectorToken?: string;

  /** Contains data about all the profiles the user is signed into */
  userInfo: {
    /** The id of the signed in user */
    userId: string;

    /** The id of the institution that the user is associated with */
    institutionId?: string;

    /** Specifies the login provider for this user profile */
    provider: "UNIC" | "EKEY" | "WAYF" | "IP";
  }[];
}> {
  return new Promise((resolve, reject) => {
    // Temporarily disable gProxy, so we can interact directly with the
    // LoginConnector endpoints!
    disable();

    // Create a new XMLHttpRequest-client
    const ajax = new XMLHttpRequest();

    // Subscribe to the readyStateChange event
    ajax.onreadystatechange = () => {
      let response: IGatewayLoginConnectorAuthResponse | { error: true };

      // If the request has not completed yet, then abort
      if (ajax.readyState !== 4) {
        return;
      }

      // Parse the response
      try {
        response = JSON.parse(ajax.responseText);
      } catch (err) {
        response = { error: true };
      }

      // Force response to be of type object
      if (!response || typeof response !== "object") {
        response = { error: true };
      }

      // If no errors occurred, then cache the received JWT
      if (!("error" in response) && response.access_token) {
        // Cache internally
        state.jwt = response.access_token;
        resolveAuthPromise();

        // Update the registerred token
        state.token = {
          AppId: profile,
          InstId: response.user_info.authenticationInfos[0].belongsTo,
          LoginProvider:
            response.user_info.authenticationInfos[0].loginProvider,
          UserId: response.user_info.authenticationInfos[0].userIdentifier,
        };

        // Resolve returned promise...
        resolve({
          /** Contains the user's authentication token from the LoginConnector */
          loginConnectorToken,

          /** Contains data about all the profiles the user is signed into */
          userInfo: response.user_info.authenticationInfos.map((authInfo) => ({
            institutionId: authInfo.belongsTo,
            provider: authInfo.loginProvider,
            userId: authInfo.userIdentifier,
          })),
        });
      } else {
        // Reject returned promise...
        reject(ajax.responseText);
      }
    };

    ajax.open("POST", proxyUrl + "auth/loginconnector", true);

    ajax.setRequestHeader("Content-Type", "application/json");

    ajax.send(
      JSON.stringify({
        appId: profile,
        loginConnectorToken,
      })
    );

    // Re-enable the gProxy right away, after we've dispatched the
    // request to fetch the user's token directly from the
    // LoginConnector
    enable();
  });
}

/**
 * Getter that parses the base64-encoded token given to the client, and
 * returns it Plain old JS object.
 */
export function getToken(): IToken {
  const gdt = state.config.get("token");

  // If the current token hasn't been parsed yet, then do so now...
  if (!state.token && gdt) {
    state.token = parseToken(gdt);
  }

  return state.token ? { ...state.token } : {};
}

/**
 * Helper that updates the currently registerred token to fit with latest
 * data from the gateway.
 */
export function updateToken(newToken?: IToken): IToken {
  const oldToken = getToken();

  const mergedToken: IToken = {
    ...oldToken,
    ...newToken,
  };

  return (state.token = mergedToken);
}

/** Helper that resets any currently active token */
export function resetToken(): void {
  state.token = null;
}

/**
 * Internal helper that parses a base64 + json-encoded token
 */
function parseToken(b64Token: string): IToken {
  // Parse the JSON-object
  try {
    // Prepare result variables for different steps of the parsing
    const jsonToken = atob(b64Token);
    const token = JSON.parse(jsonToken) as IToken;

    // We don't want to expose the "secret" publicly...
    token.secret = token.Secret = undefined;
    delete token.secret;
    delete token.Secret;

    // Return the parsed token
    return token;
  } catch (err) {
    console.warn(
      "parseToken(): An error was thrown when parsing JSON-token.",
      err
    );
    return {};
  }
}

/**
 * Getter that returns the JWT token assigned to the client by the backend.
 */
export async function getJWT(): Promise<string> {
  await authPromise;
  return state.jwt;
}

/**
 * Getter that returns the configurations given when the plugin was
 * injected.
 */
export function getConfig(): Promise<{ [key: string]: string | undefined }> {
  return JSON.parse(JSON.stringify(state.config));
}

/** Getter that returns the base URL to the backend server */
export function getBackendUrl(): string {
  return proxyUrl;
}

/**
 * Some websites need URL-based authentication. Using this method, a URL
 * will be returned that takes the user through the proxy backend and
 * ensures that authentication parameters are applied.
 *
 * @param url - The URL that should be opened.
 * @param authMethod - Specifies the authentication method that should be
 *        used. If omitted, it will automatically detect which
 *        authentication to use.
 *
 * @return {string} The encoded URL, that will send the user to the
 * proxy first and then to the given URL.
 *
 * @version v1.0, 26-09-2013
 * @access public
 *
 * @author Mads Felskov Agersten <mads@felskov-aps.dk>
 */
export function forwardTo(url: string, authMethod?: string): string {
  // Parse the domain from the URL
  const domain = resolveDomain(url);

  // Should we auto-detect method?
  if (!authMethod) {
    authMethod = domainForwardMap.get(domain);
  }

  // If no auth method was specified, then simply navigate as usually
  if (!authMethod) {
    return url;
  }

  // Parse the URL and return the result
  return (
    proxyUrl +
    "secureforward/" +
    encodeURIComponent(authMethod) +
    "/" +
    encodeURIComponent(url) +
    "?access_token=" +
    encodeURIComponent(state.jwt)
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const requestCache = (url: string | RegExp): void => {
  /** legacy method, now a noop */
  console.warn("gProxy.requestCache() has been deprecated!");
};
export const requestCaching = requestCache;

/** Disables interception of XHR */
export function disable(): void {
  xhook.disable();
  window.fetch = originalFetch;
}

/** Enables interception of XHR */
export function enable(): void {
  if (location.protocol === "file:") {
    return;
  }

  xhook.enable();
  window.fetch = fetch;
}

/** Expose list of intercepted requests that are running through Gateway */
export function getInterceptedRequests(): string[] {
  return AuthMiddleware.getInterceptedRequests();
}

enable();
init();

if (location.protocol === "file:") {
  console.warn("gProxy isn't supported on file:// protocol");
  xhook.disable();
}

// Expose gProxy publicly
window.gProxy = {
  applyLoginConnectorToken,
  authenticateToken,
  disable,
  enable,
  fetchGuestToken,
  fetchLoginConnectorToken,
  forwardTo,
  getBackendUrl,
  getConfig,
  getInterceptedRequests,
  getJWT,
  getToken,
  requestCache,
  requestCaching,
  resetToken,
  updateToken,
};

declare global {
  interface Window {
    gProxy: {
      applyLoginConnectorToken: typeof applyLoginConnectorToken;
      authenticateToken: typeof authenticateToken;
      disable: typeof disable;
      enable: typeof enable;
      fetchGuestToken: typeof fetchGuestToken;
      fetchLoginConnectorToken: typeof fetchLoginConnectorToken;
      forwardTo: typeof forwardTo;
      getBackendUrl: typeof getBackendUrl;
      getConfig: typeof getConfig;
      getInterceptedRequests: typeof getInterceptedRequests;
      getJWT: typeof getJWT;
      getToken: typeof getToken;
      requestCache: typeof requestCache;
      requestCaching: typeof requestCaching;
      resetToken: typeof resetToken;
      updateToken: typeof updateToken;
    };
  }
}
