/**
 * Helper-method that uses the browser to resolve the domain based on a
 * given URL.
 */
export function resolveDomain(url: string): string {
  // We're taking advantage of the browser's resolving of hrefs on a-tags,
  // so create a temporary tag
  const a = document.createElement("a");

  // Inject the given URL into the a-tag
  a.href = url;

  // ... And return the hostname (ie. domain), as resolved by the browser
  return a.hostname;
}
